import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { type UseMutateFunction } from '@tanstack/react-query'
import dayjs from 'dayjs'
import {
  type Note,
  NoteType,
  SourceDatabase,
  filenameToDate,
  isCalendarNote,
  isFolder,
  isTeamspaceNote,
} from '../utils/syncUtils'
import { useCreateNote, type CreateOptions } from '../hooks/useCreateNote'
import {
  type SidebarEntry,
  createSidebarEntries,
  exportNoteChildren,
  findNoteKey,
} from '../modules/sidebar/SidebarBuilder'
import { type useNoteConfig } from '../hooks/useNote'
import { cacheKeys } from '../utils/queryKeyFactory'
import useStarterNotes from '../hooks/useStarterNotes'
import { useManageSubscription } from '../hooks/useManageSubscription'
import { trackEvent } from '../lib/analytics'
import {
  useSelectedRecordName,
  useSelectedRecordNameDispatch,
} from './SelectedRecordNameProvider'
import {
  useSelectedDate,
  useSelectedDateDispatch,
} from './SelectedDateProvider'
import { useUserState } from './UserProvider'
import {
  useCachedNotesQueryClient,
  usePrivateCalendarNotes,
  usePrivateProjectNotes,
  useTeamCalendarNotes,
  useTeamProjectNotes,
} from './CachedNotesProvider'
import { useNotification } from './NotificationProvider'

type SidebarProviderContextType = {
  noteKey: useNoteConfig | null
  handleExportSupabaseTeamspace: (_recordName: string) => void
  handleSelectRecordName: (_recordName: string) => void
  handleRevealNote: (_recordName: string, _range?: Range) => void
  privateProjectNotesMap: Map<string, Note> | undefined
  privateCalendarNotesMap: Map<string, Note> | undefined
  privateNotesMap: Map<string, Note> | undefined
  teamProjectNotesMap: Map<string, Note> | undefined
  teamCalendarNotesMap: Map<string, Note> | undefined
  teamNotesMap: Map<string, Note> | undefined
  notesMap: Map<string, Note>
  sidebarEntries: SidebarEntry[]
  createNote: UseMutateFunction<Note, Error, CreateOptions, Map<string, Note>>
  breadcrumb: string[]
  setBreadcrumb: React.Dispatch<React.SetStateAction<string[]>>
  shouldForceUpdateEditor: number
}

export const SidebarContext = createContext<
  SidebarProviderContextType | undefined
>(undefined)

export function useSidebarProvider() {
  const context = useContext(SidebarContext)
  if (context === undefined) {
    throw new Error('useSidebarProvider must be used within a SidebarProvider')
  }
  return context
}

type Props = {
  onOpenCommandBar: (_visible: boolean, _search: string) => void
  openPaywall: () => void
  isGuest: boolean
  isExpired: boolean
  children: ReactNode
}

export function SidebarProvider({
  onOpenCommandBar,
  openPaywall,
  isGuest,
  isExpired,
  children,
}: Props): React.JSX.Element {
  const selectedRecordName = useSelectedRecordName()
  const setSelectedRecordName = useSelectedRecordNameDispatch()
  const { createTeamspaceStarterNotes } = useStarterNotes()
  const user = useUserState()
  const { isLoading: isLoadingPrivateProjectNotes, data: privateProjectNotes } =
    usePrivateProjectNotes()
  const privateProjectNotesMap = privateProjectNotes as
    | Map<string, Note>
    | undefined
  const { isLoading: isLoadingTeamProjectNotes, data: teamProjectNotes } =
    useTeamProjectNotes()
  const teamProjectNotesMap = teamProjectNotes as Map<string, Note> | undefined
  const { data: privateCalendarNotesMap } = usePrivateCalendarNotes()
  const privateNotesMap = useMemo(() => {
    if (
      privateProjectNotesMap === undefined ||
      privateCalendarNotesMap === undefined
    ) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      return undefined
    }
    return new Map([...privateProjectNotesMap, ...privateCalendarNotesMap])
  }, [privateProjectNotesMap, privateCalendarNotesMap])

  const { data: teamCalendarNotesMap } = useTeamCalendarNotes()
  const teamNotesMap = useMemo(() => {
    if (
      teamProjectNotesMap === undefined ||
      teamCalendarNotesMap === undefined
    ) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      return undefined
    }
    return new Map([...teamProjectNotesMap, ...teamCalendarNotesMap])
  }, [teamProjectNotesMap, teamCalendarNotesMap])
  const notesMap = useMemo(
    () => new Map([...(privateNotesMap ?? []), ...(teamNotesMap ?? [])]),
    [privateNotesMap, teamNotesMap]
  )
  const [needsAttention, setNeedsAttention] = useState(false)
  const [shouldForceUpdateEditor, setShouldForceUpdateEditor] = useState(0)
  const selectedDate = useSelectedDate()
  const selectedDateDispatch = useSelectedDateDispatch()
  const [breadcrumb, setBreadcrumb] = useState<string[]>([])
  const { showNotification, hideNotification } = useNotification()

  const handleRevealNote = useCallback(
    (recordName: string, _range?: Range) => {
      const note = notesMap.get(recordName)
      setNeedsAttention(true)
      if (note) {
        if (isCalendarNote(note.noteType)) {
          const { date, timeframe } = filenameToDate(note.filename)
          if (date == null) {
            const errorDetails = {
              message: 'Error: Invalid note filename',
              description: "Calendar note doesn't contain a valid date",
              filename: note.filename,
              noteType: note.noteType,
            }

            showNotification({
              title: errorDetails.message,
              message: errorDetails.description,
              icon: 'fa-exclamation-triangle text-red-500',
            })

            trackEvent(
              'WEB - SidebarProvider.handleRevealNote.error',
              errorDetails
            )
          } else {
            if (timeframe === 'day') {
              if (note.parent) {
                setSelectedRecordName(`daily_${note.parent}`)
              }
              selectedDateDispatch({ type: 'setDay', date })
            }
            if (timeframe === 'week') {
              if (note.parent) {
                setSelectedRecordName(`weekly_${note.parent}`)
              }
              selectedDateDispatch({
                type: 'setWeek',
                week: date.week(),
                year: date.year(),
              })
            }
          }
        } else {
          setSelectedRecordName(recordName)
        }

        const buildBreadcrumb = (recordName: string): string[] => {
          const crumbs: string[] = []
          const note = notesMap.get(recordName)
          if (note) {
            if (note.source === SourceDatabase.SUPABASE) {
              const parent = note.parent ? notesMap.get(note.parent) : undefined
              if (parent?.recordName) {
                crumbs.push(
                  ...buildBreadcrumb(parent.recordName),
                  parent.recordName
                )
              }
            } else {
              // Iteratively split the path of the note.filename. It's like folder1/folder2/folder3/filename.md, we need to add folder1/folder2/folder3, folder1/folder2, folder1
              const path = note.filename.split('/')
              for (let i = 0; i < path.length - 1; i++) {
                crumbs.push(path.slice(0, i + 1).join('/'))
              }
            }
          }

          // Add the root as well ('teamspaces' or 'private notes')
          if (note?.noteType) {
            crumbs.push(isTeamspaceNote(note.noteType) ? 'teamspaces' : 'notes')
          }

          return crumbs
        }

        setBreadcrumb([note.filename, ...buildBreadcrumb(selectedRecordName)])

        // EDIT: The selection is always off, comment it out for now, until we figured out a better way of highlighting the selected reference, maybe some attribute
        // setTextSelection({ from: range?.from ?? 0, to: range?.to ?? 0 })
      }
    },
    [
      notesMap,
      selectedRecordName,
      showNotification,
      selectedDateDispatch,
      setSelectedRecordName,
    ]
  )

  const { createPrivateStarterNotes: createStarterNotes } = useStarterNotes()
  const [isCreatingStarterNotes, setIsCreatingStarterNotes] = useState(false)

  useEffect(() => {
    if (!isCreatingStarterNotes && user) {
      setIsCreatingStarterNotes(true)

      void createStarterNotes(
        () => {
          // Started
          showNotification({
            title: 'Welcome to NotePlan',
            message: 'Setting you up for a productive start...',
            icon: 'fa-sun text-orange-400',
          })
        },
        () => {
          // Ready for open daily note
          setSelectedRecordName('daily')
          selectedDateDispatch({ type: 'setDay', date: dayjs() })

          // This is necessary so that we reload the cached notes inside the TipTap editor object, otherwise clicking on "Start Here" will create a new note instead of opening the existing
          setShouldForceUpdateEditor((prev) => prev + 1)
        }
      ).finally(() => {
        setIsCreatingStarterNotes(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const createNoteMutation = useCreateNote((note: Note) => {
    if (note.recordName) {
      // Select the created note
      if (!isFolder(note)) {
        handleRevealNote(note.recordName)
      }

      // We just created a new teamspace, so add the starter notes
      if (note.noteType === NoteType.TEAM_SPACE) {
        showNotification({
          title: 'Teamspace',
          message: 'Setting up your teamspace...',
          icon: 'fa-screen-users text-green-400',
        })
        void createTeamspaceStarterNotes(note.recordName, () => {
          // Open today's note of the teamspace, now with the updated caches
          setNeedsAttention(true)
          setSelectedRecordName(`daily_${note.recordName}`)
          selectedDateDispatch({ type: 'setDay', date: dayjs() })
          setBreadcrumb([note.recordName])
        })
      }
    }
  })

  const { openManageSubscription } = useManageSubscription()

  const sidebarEntries: SidebarEntry[] = useMemo(
    () =>
      createSidebarEntries(
        user,
        isLoadingPrivateProjectNotes,
        privateNotesMap,
        isLoadingTeamProjectNotes,
        teamNotesMap,
        createNoteMutation.mutate,
        onOpenCommandBar,
        openManageSubscription,
        openPaywall,
        isGuest,
        isExpired
      ),
    [
      user,
      isLoadingPrivateProjectNotes,
      privateNotesMap,
      isLoadingTeamProjectNotes,
      teamNotesMap,
      createNoteMutation.mutate,
      onOpenCommandBar,
      openManageSubscription,
      openPaywall,
      isGuest,
      isExpired,
    ]
  )
  const noteKey: useNoteConfig | null = useMemo(
    () => findNoteKey(sidebarEntries, selectedRecordName, selectedDate),
    [sidebarEntries, selectedRecordName, selectedDate]
  )

  // function wrapped in useCallback to initiate the export of a note and it's children
  const handleExportSupabaseTeamspace = useCallback(
    (recordName: string) => {
      const notes = new Map([
        ...(privateNotesMap ?? []),
        ...(teamNotesMap ?? []),
      ]) // Get all the notes at this point, the function will find the actual children
      const entry = notes.get(recordName) // Find the note object for the folder we want to export (i.e. teamspace)

      // TODO: If the entry is not found, export all private notes
      if (entry) {
        showNotification({
          title: 'Exporting attachments...',
          message: 'Downloading, this can take a while.',
          icon: 'fa-download text-green-400',
          autoHide: false,
        })
        void exportNoteChildren(entry, notes).then(() => {
          hideNotification()
        })
      }
    },
    [hideNotification, privateNotesMap, showNotification, teamNotesMap]
  )

  const handleSelectRecordName = useCallback(
    (recordName: string) => {
      setSelectedRecordName(recordName)

      const teamspace =
        recordName.split('_').length > 0 ? recordName.split('_')[1] : undefined
      if (
        recordName.startsWith('weekly') &&
        (selectedDate.active === 'day' || selectedDate.teamspace !== teamspace)
      ) {
        selectedDateDispatch({
          type: 'transform',
          to: 'week',
          teamspace,
        })
      }
      if (
        recordName.startsWith('daily') &&
        (selectedDate.active === 'week' || selectedDate.teamspace !== teamspace)
      ) {
        selectedDateDispatch({
          type: 'transform',
          to: 'day',
          teamspace,
        })
      }
    },
    [
      selectedDate.active,
      selectedDate.teamspace,
      selectedDateDispatch,
      setSelectedRecordName,
    ]
  )

  const cachedNotesQueryClient = useCachedNotesQueryClient()
  useEffect(() => {
    // check to make sure we use the new map data structure
    if (privateProjectNotes && Array.isArray(privateProjectNotes)) {
      void cachedNotesQueryClient?.invalidateQueries({
        queryKey: cacheKeys.privateNotes(),
      })
    }
    if (teamProjectNotes && Array.isArray(teamProjectNotes)) {
      void cachedNotesQueryClient?.invalidateQueries({
        queryKey: cacheKeys.teamNotes(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // scroll to the selected record name
  useEffect(() => {
    if (needsAttention) {
      const element = document.getElementById(selectedRecordName)
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      setBreadcrumb([])
      setNeedsAttention(false)
    }
  }, [selectedRecordName, needsAttention])

  // performance optimization
  const context = useMemo(
    () => ({
      noteKey,
      handleExportSupabaseTeamspace,
      handleSelectRecordName,
      handleRevealNote,
      privateProjectNotesMap,
      privateCalendarNotesMap,
      privateNotesMap,
      teamProjectNotesMap,
      teamCalendarNotesMap,
      teamNotesMap,
      notesMap,
      sidebarEntries,
      createNote: createNoteMutation.mutate,
      breadcrumb,
      setBreadcrumb,
      shouldForceUpdateEditor,
    }),
    [
      noteKey,
      handleExportSupabaseTeamspace,
      handleSelectRecordName,
      handleRevealNote,
      privateProjectNotesMap,
      privateCalendarNotesMap,
      privateNotesMap,
      teamProjectNotesMap,
      teamCalendarNotesMap,
      teamNotesMap,
      notesMap,
      sidebarEntries,
      createNoteMutation.mutate,
      breadcrumb,
      shouldForceUpdateEditor,
    ]
  )
  return (
    <SidebarContext.Provider value={context}>
      {children}
    </SidebarContext.Provider>
  )
}
