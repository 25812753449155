import {
  QueryClient,
  QueryKey,
  useMutation,
  useQueries,
  useQuery,
} from '@tanstack/react-query'
import axios from 'axios'
import {
  useCachedNotesContext,
  useCachedNotesQueryClient,
} from '../providers/CachedNotesProvider'
import { cacheKeys } from '../utils/queryKeyFactory'
import { useUserState } from '../providers/UserProvider'
import { Dayjs } from 'dayjs'
import { determineBaseURL } from '../utils/backend'

export type SigninRequestParams = {
  userid: string
  username: string
  password: string
}

type Response = { success: boolean } | { error: string }

const backendClient = axios.create({
  baseURL: determineBaseURL(window.location.hostname, 'apple'),
  headers: {
    'Content-Type': 'application/json',
  },
})

export function useAppleCalDAVSignin() {
  const cachedNotesQueryClient = useCachedNotesQueryClient()
  return useMutation<Response, Error, SigninRequestParams>({
    mutationFn: async (parameters: SigninRequestParams) => {
      const response = await backendClient.post<Response>(
        'signin.php',
        parameters
      )
      return response.data
    },
    onSuccess: (_data: Response, variables: SigninRequestParams) => {
      void cachedNotesQueryClient.invalidateQueries({
        queryKey: cacheKeys.appleCalendars(variables.userid),
      })
    },
  })
}

type SignoutRequestParams = {
  userid: string
}

export function useAppleCalDAVSignout() {
  const cachedNotesQueryClient = useCachedNotesQueryClient()
  return useMutation<Response, Error, SignoutRequestParams>({
    mutationFn: async (parameters: SignoutRequestParams) => {
      const response = await backendClient.post<Response>(
        'signout.php',
        parameters
      )
      return response.data
    },
    onSuccess: (_data: Response, variables: SignoutRequestParams) => {
      cachedNotesQueryClient.removeQueries({
        queryKey: cacheKeys.appleCalendars(variables.userid),
      })
    },
  })
}

async function handleApiAppleError<T>(
  queryClient: QueryClient,
  queryKey: QueryKey | undefined,
  request: () => Promise<T>
) {
  try {
    return await request()
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        // cache "User not found" aka not signed in to prevent further requests
        return [] as T
      }
      const httpCodes = [400, 405]
      if (error.response?.status && httpCodes.includes(error.response.status)) {
        queryClient.removeQueries(queryKey)
      }
    }
    throw error
  }
}

export type VCalendar = {
  url: string
  displayName: string
  calendarColor: string
}

export function useAppleCalendars() {
  const user = useUserState()
  const userid = user?.cloudKitUserId ?? user?.supabaseUserId
  const cachedNotesQueryClient = useCachedNotesQueryClient()

  return useQuery<VCalendar[], Error>({
    enabled: Boolean(userid),
    context: useCachedNotesContext(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps -- dependency is not detected properly because of the factory function
    queryKey: userid ? cacheKeys.appleCalendars(userid) : undefined,
    queryFn: () =>
      handleApiAppleError(
        cachedNotesQueryClient,
        userid ? cacheKeys.appleCalendars(userid) : undefined,
        async () => {
          const response = await backendClient.get<VCalendar[]>(
            'calendars.php',
            {
              params: { userid },
            }
          )
          return response.data
        }
      ),
  })
}

export type VEvent = {
  summary: string
  description: string
  location: string
  start: string
  end: string
  allday: boolean
  calendarName: string
  calendarColor: string
}

async function fetchEvents(
  cachedNotesQueryClient: QueryClient,
  userid: string,
  calendar: VCalendar,
  month: string
) {
  return handleApiAppleError(
    cachedNotesQueryClient,
    cacheKeys.appleEvents(userid, calendar.url, month),
    async () => {
      const response = await backendClient.get<VEvent[]>('events.php', {
        params: { userid, calendarurl: calendar.url, month },
      })
      if (Array.isArray(response.data)) {
        return response.data.map((vevent: VEvent) => ({
          ...vevent,
          calendarName: calendar.displayName,
          calendarColor: calendar.calendarColor,
        })) as VEvent[]
      }

      throw new Error('Invalid response data')
    }
  )
}

/**
 * Custom hook to fetch Apple Calendar events.
 *
 * @param urls - Array of iCalendar URLs.
 * @param date - Date as Dayjs object.
 * @returns Array of query results for each iCalendar URL.
 */
export function useAppleEvents(date: Dayjs) {
  const user = useUserState()
  const userid = user?.cloudKitUserId ?? user?.supabaseUserId
  const { data: appleCalendars = [] } = useAppleCalendars()
  const clonedDate: Dayjs = date.clone()
  const month: string = clonedDate.format('YYYY-MM')
  const cachedNotesQueryClient = useCachedNotesQueryClient()

  return useQueries<VEvent[]>({
    context: useCachedNotesContext(),
    queries:
      userid && Array.isArray(appleCalendars)
        ? appleCalendars.map((calendar) => ({
            enabled: Boolean(userid),
            // eslint-disable-next-line @tanstack/query/exhaustive-deps
            queryKey: cacheKeys.appleEvents(userid, calendar.url, month),
            queryFn: () =>
              fetchEvents(cachedNotesQueryClient, userid, calendar, month),
            refetchInterval: 5 * 60 * 1000, // Poll every 5 minutes
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
            retry: false, // disable retrying to save function calls
          }))
        : [],
  })
}
