import { createContext, useContext } from 'react'
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { mainId, useUserState } from './UserProvider'
import axios, { AxiosError } from 'axios'
import { googleLogout } from '@react-oauth/google'
import { determineBaseURL } from '../utils/backend'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

export const accessTokenContext = createContext<QueryClient | undefined>(
  undefined
)

export function useAccessTokenQueryClient() {
  const context = useContext(accessTokenContext)
  if (context === undefined) {
    throw new Error(
      'useAccessTokenContext must be used within a AccessTokenProvider'
    )
  }
  return context
}

const accessTokenMaxAge = 1000 * 60 * 59 // 59 minutes
const accessTokenQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: accessTokenMaxAge,
      staleTime: accessTokenMaxAge,
      // disable too much refetching
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 3,
    },
  },
})

const backendClient = axios.create({
  baseURL: determineBaseURL(window.location.hostname, 'google'),
  headers: {
    'Content-Type': 'application/json',
  },
})

// https://tanstack.com/query/v4/docs/react/plugins/createSyncStoragePersister
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  key: 'accessToken',
})

export type AccessTokenResponse = {
  token: string
  expires: number
}

// query for using the access token, will be refetched automatically if it expires
export function useAccessToken() {
  const user = useUserState()
  const userId = mainId(user)

  return useQuery<AccessTokenResponse, AxiosError>({
    enabled: Boolean(userId),
    context: accessTokenContext,
    refetchInterval: accessTokenMaxAge,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['accessToken'],
    queryFn: async () => {
      const response = await backendClient.post<AccessTokenResponse>(
        'auth.php',
        {
          userid: userId,
        }
      )
      return response.data
    },
  })
}

export type SigninParams = {
  code: string
  userId: string
}

// mutation to forward the auth code to the backend and get the access token in return
export function useSignIn() {
  const queryClient = useAccessTokenQueryClient()
  return useMutation<AccessTokenResponse, AxiosError, SigninParams>({
    retry: 3, // retry in case our backend is not reachable
    mutationFn: async ({ code, userId }: SigninParams) => {
      const response = await backendClient.post<AccessTokenResponse>(
        'signin.php',
        {
          code,
          userid: userId,
        }
      )
      return response.data
    },
    onMutate: async () => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries(['accessToken'])
    },
    onError: () => {
      queryClient.setQueryData(['accessToken'], undefined)
    },
    onSuccess: (data: AccessTokenResponse) => {
      queryClient.setQueryData(['accessToken'], data)
    },
  })
}

type SignOutResponse = {
  success: boolean
}

type SignOutRequestParams = {
  userid: string
}

export function useSignOut() {
  const queryClient = useAccessTokenQueryClient()
  return useMutation<SignOutResponse, AxiosError, SignOutRequestParams>({
    mutationFn: async ({ userid }: SignOutRequestParams) => {
      const response = await backendClient.post<SignOutResponse>(
        'signout.php',
        {
          userid,
        }
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.clear()
      googleLogout()
    },
  })
}

export default function AccessTokenProvider({
  children,
}: {
  children: React.ReactNode
}) {
  function validateToken() {
    const accessToken: AccessTokenResponse | undefined =
      accessTokenQueryClient.getQueryData(['accessToken'])
    // in case we got an old token, initiate a refetch
    if (accessToken && accessToken.expires < Date.now()) {
      void accessTokenQueryClient.invalidateQueries(['accessToken'])
    }
  }

  return (
    <PersistQueryClientProvider
      context={accessTokenContext}
      client={accessTokenQueryClient}
      persistOptions={{
        persister: localStoragePersister,
        maxAge: accessTokenMaxAge,
      }}
      onSuccess={validateToken}
    >
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} context={accessTokenContext} position="bottom-right" /> */}
    </PersistQueryClientProvider>
  )
}
