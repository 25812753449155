import { type UseQueryResult } from '@tanstack/react-query'
import { type Dayjs } from 'dayjs'
import { useMemo, type Dispatch, type SetStateAction } from 'react'
import { getGroupedEvents } from '../utils/getGroupedEvents'
import { calculateOffsetAndHeight } from '../utils/event'
import { useGoogleCalendarEvents } from '../../../../hooks/useGoogleCalendarEvents'
import { flatMapCalendarEvents } from '../../../../utils/calendar'
import { type CalendarEvent } from '../../CalendarEvent'
import { type PlaceholderEvent } from '../types'
import { SingleEvent } from './SingleEvent'
import { MultipleEvents } from './MultipleEvents'
import { VEvent, useAppleEvents } from '../../../../hooks/AppleCalDAV'

export type CalendarEventsProps = {
  setCalendarEventDrag: Dispatch<SetStateAction<CalendarEvent | null>>
  selectedDay: Dayjs
  currentEvents: CalendarEvent[]
  placeholderEvent: PlaceholderEvent | null
}

export function CalendarEvents({
  setCalendarEventDrag, // TODO refactor into a context to avoid massive re-renderings through dragging
  selectedDay,
  currentEvents,
  placeholderEvent,
}: CalendarEventsProps) {
  const parent = document.querySelector('#week-columns')
  let segmentHeight = 28
  if (parent) {
    segmentHeight = parent.getBoundingClientRect().height / 97
  }

  const currentEventsToday = currentEvents.filter((event) =>
    event.startDate.isSame(selectedDay, 'day')
  )
  const { data: googleEvents, isFetching: googleIsFetching } =
    useGoogleCalendarEvents(selectedDay)
  const queriesResults = useAppleEvents(selectedDay)
  const appleIsFetching = queriesResults.some((result) => result.isFetching)

  const appleCalendarEvents = useMemo(() => {
    const allAppleEvents = flatMapCalendarEvents(
      queriesResults as UseQueryResult<VEvent[]>[],
      selectedDay
    )

    return allAppleEvents
      .filter((event) => !event.allDay)
      .filter((event) => {
        return (
          event.startDate.isSame(selectedDay, 'day') ||
          event.endDate.isSame(selectedDay, 'day') ||
          (event.startDate.isBefore(selectedDay, 'day') &&
            event.endDate.isAfter(selectedDay, 'day'))
        )
      })
  }, [queriesResults, selectedDay])

  const groupedEvents = useMemo(() => {
    const combinedEvents = [
      ...currentEventsToday,
      ...(googleEvents?.filter((event) => !event.allDay) ?? []),
      ...appleCalendarEvents,
    ]

    // Filter out events that have a description that starts with "NPTB:", these are timeblocks created by NotePlan
    const filteredEvents = combinedEvents.filter(
      (event): event is CalendarEvent => {
        return !event.description?.startsWith('NPTB:')
      }
    )

    return getGroupedEvents(filteredEvents)
  }, [currentEventsToday, googleEvents, appleCalendarEvents])

  const eventElements = useMemo(() => {
    return groupedEvents
      .filter((group) => group.length > 0)
      .map((group) => {
        if (group.length === 1) {
          return (
            <SingleEvent
              key={`event-${group[0].id}`}
              event={group[0]}
              segmentHeight={segmentHeight}
              setCalendarEventDrag={setCalendarEventDrag}
              selectedDay={selectedDay}
            />
          )
        }
        return (
          <MultipleEvents
            key={`event-group-${group[0].startDate.toISOString()}`}
            events={group}
            segmentHeight={segmentHeight}
            setCalendarEventDrag={setCalendarEventDrag}
            selectedDay={selectedDay}
          />
        )
      })
  }, [groupedEvents, segmentHeight, selectedDay, setCalendarEventDrag])

  if (placeholderEvent) {
    const { eventHeight, offsetPxTop } = calculateOffsetAndHeight(
      placeholderEvent,
      segmentHeight,
      selectedDay
    )

    if (!Number.isNaN(eventHeight)) {
      eventElements.push(
        <li
          key='event-placeholder'
          id='event-placeholder'
          className='col-start-1 flex rounded-lg border-2 border-orange-500'
          style={{
            gridRow: placeholderEvent.position,
            transform: `translateY(${offsetPxTop.toString()}px)`,
            height: eventHeight - 2,
          }}
        />
      )
    }
  }

  if (googleIsFetching || appleIsFetching) {
    eventElements.push(
      <div key='loading' className='sticky top-0 pr-4 text-right'>
        <i className='fa-solid fa-circle-notch fa-spin fa-xs text-gray-300 dark:text-gray-600' />
      </div>
    )
  }

  return <>{eventElements}</>
}
