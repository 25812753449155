import { type Dayjs } from 'dayjs'
import { type UseQueryResult } from '@tanstack/react-query'
import { type CalendarEvent } from '../../CalendarEvent'
import { useGoogleCalendarEvents } from '../../../../hooks/useGoogleCalendarEvents'
import { flatMapCalendarEvents } from '../../../../utils/calendar'
import { useDarkMode } from '../../../../providers/DarkModeProvider'
import { darkenColor } from '../../../../utils/color'
import { VEvent, useAppleEvents } from '../../../../hooks/AppleCalDAV'
import { useMemo } from 'react'
import classNames from 'classnames'

export function AllDayEvents({
  selectedDay,
  labelWidth,
}: {
  selectedDay: Dayjs
  labelWidth: number
}) {
  const isDarkMode = useDarkMode()
  const { data: googleEvents } = useGoogleCalendarEvents(selectedDay)
  const queriesResults = useAppleEvents(selectedDay)
  const appleCalendarEvents = useMemo(() => {
    return flatMapCalendarEvents(
      queriesResults as UseQueryResult<VEvent[]>[],
      selectedDay
    )
      .filter((event) => event.allDay)
      .filter((event) => {
        return (
          event.startDate.isSame(selectedDay, 'day') ||
          event.endDate.isSame(selectedDay, 'day') ||
          (event.startDate.isBefore(selectedDay, 'day') &&
            event.endDate.isAfter(selectedDay, 'day'))
        )
      })
  }, [queriesResults, selectedDay])

  const allDayEvents: CalendarEvent[] = useMemo(() => {
    return [
      ...(googleEvents
        ? googleEvents.filter((event) => Boolean(event.allDay))
        : []),
      ...appleCalendarEvents,
    ].map((event) => {
      return {
        ...event,
        color:
          event.color && isDarkMode
            ? darkenColor(event.color, 50)
            : event.color,
      }
    })
  }, [googleEvents, appleCalendarEvents, isDarkMode])

  return (
    <div className='flex border-b-2'>
      <div
        className='flex flex-shrink-0 text-xs leading-tight text-gray-400'
        style={{ width: `${labelWidth.toString()}px` }}
      >
        <div className='w-full self-end pb-0.5 pr-2 text-right'>all-day</div>
      </div>
      <div className='grid flex-auto grid-cols-1 grid-rows-1 pt-0.5'>
        {allDayEvents.map((event) => {
          return (
            <div
              key={event.id}
              className={classNames(
                'mb-0.5 truncate rounded-md px-2 text-xs font-semibold text-gray-700 dark:text-gray-300',
                event.color ? '' : 'event-content-background-blue'
              )}
              style={{ backgroundColor: event.color }}
            >
              {event.content}
            </div>
          )
        })}
      </div>
    </div>
  )
}
