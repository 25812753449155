import {
  useQuery,
  QueryClient,
  useQueries,
  type QueryKey,
} from '@tanstack/react-query'
import {
  PersistQueryClientProvider,
  type PersistedClient,
  type Persister,
} from '@tanstack/react-query-persist-client'
import { get, set, del } from 'idb-keyval'
import { useContext, createContext, useCallback } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { fetchPrivateNotes, fetchTeamNotes } from '../lib/supabase/fetch'
import { mapSet } from '../utils/mapAsState'
import { useSafeQuery } from '../hooks/useSafeQuery'
import {
  type Note,
  filenameToKey,
  isCalendarNote,
  isTeamspaceNote,
  getSupabaseFileExtension,
} from '../utils/syncUtils'
import { cacheKeys } from '../utils/queryKeyFactory'
import { useCloudKitClient } from './CloudKitClientProvider'
import { mainId, useUserState, type User } from './UserProvider'

// #region data
export const cachedNotesContext = createContext<QueryClient | undefined>(
  undefined
)

export function useCachedNotesContext() {
  return cachedNotesContext
}

export function useCachedNotesQueryClient() {
  const queryClient = useContext(cachedNotesContext)
  if (queryClient === undefined) {
    throw new Error(
      'useCachedNotesQueryClient must be used within a NotesProvider'
    )
  }
  return queryClient
}

// Because of notification "disconnection" issues, set the cache time to 24h
const cacheTime = 1000 * 60 * 60 * 24 // 24h
const cachedNotesQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: 'always', // TODO do we need set this depending on the data?
      cacheTime,
    },
  },
})

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 * @see https://tanstack.com/query/v4/docs/react/plugins/persistQueryClient#building-a-persister
 */
function createIDBPersister(idbValidKey: IDBValidKey = 'notes') {
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client)
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey)
    },
    removeClient: async () => {
      await del(idbValidKey)
    },
  } as Persister
}

const idbPersister = createIDBPersister()

// #endregion

// #region notes

export function usePrivateProjectNotes(byTitle = false) {
  const ck = useCloudKitClient()
  const user = useUserState()
  const userId = mainId(user)

  return useSafeQuery<Map<string, Note> | Map<string, Note[]>, Error>({
    enabled: Boolean(userId),
    context: cachedNotesContext,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: cacheKeys.privateProjectNotes(userId),
    queryFn: async () => {
      // eslint-disable-next-line no-console
      console.log('usePrivateProjectNotes user', user)

      // Add private notes from CloudKit
      if (user?.cloudKitUserId) {
        return ck.fetchPrivateNotes('project')
      }

      if (user?.supabaseUserId) {
        return fetchPrivateNotes(user.supabaseUserId, 'project')
      }

      throw new Error('No client signed in')
    },
    select: useCallback(
      (data: Map<string, Note>) => {
        if (byTitle) {
          const notes = new Map<string, Note[]>()
          data.forEach((note: Note) => {
            const title = isCalendarNote(note.noteType)
              ? filenameToKey(note.filename)
              : note.title
            if (!title) return

            // exclude notes that starts with an @
            if (!title.startsWith('@')) {
              if (notes.has(title)) {
                notes.get(title).push(note)
              } else {
                notes.set(title, [note])
              }
            }
          })
          return notes
        }
        return data
      },
      [byTitle]
    ),
  })
}

export function usePrivateCalendarNotes() {
  const ck = useCloudKitClient()
  const user = useUserState()
  const userId = mainId(user)
  const { isSuccess, fetchStatus } = usePrivateProjectNotes()

  return useSafeQuery<Map<string, Note>, Error>({
    enabled: Boolean(userId) && isSuccess && fetchStatus === 'idle',
    context: cachedNotesContext,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: userId ? cacheKeys.privateCalendarNotes(userId) : undefined,
    queryFn: async () => {
      // eslint-disable-next-line no-console
      console.log('usePrivateCalendarNotes user', user)

      // Add private notes from CloudKit
      if (user?.cloudKitUserId) {
        return ck.fetchPrivateNotes('calendar')
      }

      if (user?.supabaseUserId) {
        return fetchPrivateNotes(user.supabaseUserId, 'calendar')
      }

      throw new Error('No client signed in')
    },
  })
}

export function useTeamProjectNotes(byTitle = false) {
  const user = useUserState()
  return useSafeQuery<Map<string, Note> | Map<string, Note[]>, Error>({
    enabled: Boolean(user) && Boolean(user?.supabaseUserId),
    context: cachedNotesContext,
    queryKey: cacheKeys.teamProjectNotes(user?.supabaseUserId),
    queryFn: async () => {
      // NOTE: We load the content from team spaces always from Supabase, even CloudKit users have to login for team spaces separately
      // eslint-disable-next-line no-console
      console.log('useTeamProjectNotes user', user?.supabaseUserId)
      return fetchTeamNotes(user?.supabaseUserId, 'project')
    },
    select: useCallback(
      (data: Map<string, Note>) => {
        if (byTitle) {
          const notes = new Map<string, Note[]>()
          data.forEach((note: Note) => {
            const title = isCalendarNote(note.noteType)
              ? filenameToKey(note.filename)
              : note.title
            if (notes.has(title)) {
              notes.get(title).push(note)
            } else {
              notes.set(title, [note])
            }
          })
          return notes
        }
        return data
      },
      [byTitle]
    ),
  })
}

// Improved handling of user and user.supabaseUserId in case those are undefined. Refactored into a more elegant solution.
export function useTeamCalendarNotes() {
  const user = useUserState()
  const { isSuccess, fetchStatus } = useTeamProjectNotes()

  const supabaseUserId = user?.supabaseUserId

  const isEnabled =
    Boolean(user) &&
    Boolean(supabaseUserId) &&
    isSuccess &&
    fetchStatus === 'idle'

  return useSafeQuery<Map<string, Note>, Error>({
    enabled: isEnabled,
    context: cachedNotesContext,
    queryKey: supabaseUserId
      ? cacheKeys.teamCalendarNotes(supabaseUserId)
      : undefined,
    queryFn: async () => {
      if (!supabaseUserId) {
        throw new Error('Supabase User ID is undefined')
      }
      // NOTE: We load the content from team spaces always from Supabase, even CloudKit users have to login for team spaces separately
      // console.log('useTeamCalendarNotes user', supabaseUserId)
      return fetchTeamNotes(supabaseUserId, 'calendar')
    },
  })
}

function updateNoteHelper(
  cachedNotesQueryClient: QueryClient,
  queryKey: QueryKey,
  updatedNote: Note
): Map<string, Note> {
  // snapshot the previous value
  const previousNotes =
    cachedNotesQueryClient.getQueryData<Map<string, Note>>(queryKey)

  // optimistically update to the new value
  cachedNotesQueryClient.setQueryData<Map<string, Note>>(
    queryKey,
    (oldData: Map<string, Note>) => {
      return mapSet(oldData, updatedNote.recordName, updatedNote)
    }
  )

  return previousNotes
}

export function updateNote(
  cachedNotesQueryClient: QueryClient,
  privateUserId: string,
  teamUserId: string,
  updatedNote: Note
): void {
  // teamUserId is optional
  if (privateUserId === undefined || updatedNote === undefined) return

  if (isTeamspaceNote(updatedNote.noteType)) {
    if (isCalendarNote(updatedNote.noteType)) {
      updateNoteHelper(
        cachedNotesQueryClient,
        cacheKeys.teamCalendarNotes(teamUserId),
        updatedNote
      )
    } else {
      updateNoteHelper(
        cachedNotesQueryClient,
        cacheKeys.teamProjectNotes(teamUserId),
        updatedNote
      )
    }
  } else if (isCalendarNote(updatedNote.noteType)) {
    updateNoteHelper(
      cachedNotesQueryClient,
      cacheKeys.privateCalendarNotes(privateUserId),
      updatedNote
    )
  } else {
    updateNoteHelper(
      cachedNotesQueryClient,
      cacheKeys.privateProjectNotes(privateUserId),
      updatedNote
    )
  }
}

type UpdateNoteReturnFallbackProps = {
  currentCachedNotesQueryClient?: QueryClient
  privateUserId?: string
  teamUserId?: string
  updatedNote?: Note
}

export function updateNoteReturnFallback({
  currentCachedNotesQueryClient,
  privateUserId,
  teamUserId,
  updatedNote,
}: UpdateNoteReturnFallbackProps) {
  // teamUserId is optional
  if (!currentCachedNotesQueryClient || !updatedNote) return

  if (isTeamspaceNote(updatedNote.noteType)) {
    if (!teamUserId) return
    if (isCalendarNote(updatedNote.noteType)) {
      return updateNoteHelper(
        currentCachedNotesQueryClient,
        cacheKeys.teamCalendarNotes(teamUserId),
        updatedNote
      )
    }
    return updateNoteHelper(
      currentCachedNotesQueryClient,
      cacheKeys.teamProjectNotes(teamUserId),
      updatedNote
    )
  }

  if (!privateUserId) return
  if (isCalendarNote(updatedNote.noteType)) {
    return updateNoteHelper(
      currentCachedNotesQueryClient,
      cacheKeys.privateCalendarNotes(privateUserId),
      updatedNote
    )
  }
  return updateNoteHelper(
    currentCachedNotesQueryClient,
    cacheKeys.privateProjectNotes(privateUserId),
    updatedNote
  )
}

// #endregion

export function useNotesExtension(user: User | undefined) {
  const ck = useCloudKitClient()

  return useSafeQuery<string, Error>({
    enabled: Boolean(user),
    context: cachedNotesContext,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: cacheKeys.extension,
    queryFn: async () => {
      // eslint-disable-next-line no-console
      console.log('[useFileExtension] user', user)
      if (user.cloudKitUserId) {
        return ck.fetchFileExtension()
      } else if (user.supabaseUserId) {
        return getSupabaseFileExtension()
      }
      throw new Error('No client signed in')
    },
  })
}

export default function CachedNotesProvider({ children }) {
  return (
    <PersistQueryClientProvider
      context={cachedNotesContext}
      client={cachedNotesQueryClient}
      persistOptions={{ persister: idbPersister, maxAge: cacheTime }}
    >
      {children}
      <ReactQueryDevtools
        initialIsOpen={false}
        context={cachedNotesContext}
        position='bottom-right'
      />
    </PersistQueryClientProvider>
  )
}
