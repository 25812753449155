import axios, { type AxiosInstance } from 'axios'
import { type User } from '../providers/UserProvider'
import { determineBaseURL } from '../utils/backend'

// Define types for the response structure from your backend
export type BackendResponse = {
  error?: string
  trial?: string
  subscribed?: boolean
  trial_start_date?: Date
  trial_end_date?: Date
  revenuecat_id?: string
  subscription_status?: boolean
  stripeCustomerId?: string
  stripeSubscriptionId?: string
  is_guest?: boolean
}

const backendClient: AxiosInstance = axios.create({
  baseURL: determineBaseURL(window.location.hostname, 'stripe'),
})

export type Address = {
  address: object
  name: string
}

export type PaymentsRequestParams = {
  paymentMethodId: string
  user: User
  email?: string
  address: Address | null
  duration: string
  isSmallTeam: boolean
  revenueCatUserId: string
  stripeCustomerId?: string
}

export async function stripeRequest<T>(
  endpoint: string,
  payload: T,
  json = true
): Promise<BackendResponse> {
  try {
    const headers = {
      'Content-Type': json
        ? 'application/json'
        : 'application/x-www-form-urlencoded',
    }
    const response = await backendClient.post<BackendResponse>(
      endpoint,
      json ? JSON.stringify(payload) : payload,
      { headers }
    )

    if (response.status !== 200) {
      throw new Error('Network response was not ok')
    }

    if (response.data.error) {
      throw new Error(response.data.error)
    }

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.statusText)
    } else if (error instanceof Error) {
      throw new TypeError(error.message)
    } else {
      throw new TypeError(`An unknown error occurred: ${String(error)}`)
    }
  }
}
