import { useEffect, useState } from 'react'
import { BlockNoteEditor } from '@packages/blocknote-core'
import { BlockNoteView, useBlockNote } from '@packages/blocknote-react'
import { useDarkMode } from '../../providers/DarkModeProvider'

export default function ReadOnlyEditor() {
  const isDarkMode = useDarkMode()
  const loadingText = 'Loading...'
  const [summary, setSummary] = useState(
    sessionStorage.getItem('summary') ?? loadingText
  )

  useEffect(() => {
    const handleSummaryChange = () => {
      const currentSummary = sessionStorage.getItem('summary')
      if (currentSummary && currentSummary !== summary) {
        setTimeout(() => {
          setSummary(currentSummary)
        }, 2000) // 2 seconds delay
        clearInterval(intervalId)
      }
    }

    window.addEventListener('storage', handleSummaryChange)

    const intervalId = setInterval(handleSummaryChange, 1000)

    return () => {
      window.removeEventListener('storage', handleSummaryChange)
      clearInterval(intervalId)
    }
  }, [summary])

  const editor = useBlockNote({
    theme: isDarkMode ? 'dark' : 'light',
    editable: false,
  })

  useEffect(() => {
    const blocks = BlockNoteEditor.notePlanToBlocks(summary, '')

    editor?.replaceBlocks(editor.topLevelBlocks, blocks)
  }, [editor, summary])

  const handleDownload = () => {
    const element = document.createElement('a')
    const file = new Blob([summary], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = 'meeting_summary.txt'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  return (
    <>
      <div className='relative'>
        <a
          className='block w-full cursor-pointer text-center bg-orange-100 py-2 text-gray-900 dark:text-gray-900 no-underline'
          href='/'
        >
          Register or sign in to save this summary
        </a>
        <button
          onClick={handleDownload}
          className='absolute top-1/2 right-2 transform -translate-y-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-sm'
        >
          Download
        </button>
      </div>
      <div className='note-body flex flex-col'>
        <div className='editor-container-wrapper'>
          <div className='editor-container'>
            <BlockNoteView editor={editor} />
          </div>
        </div>
      </div>
    </>
  )
}
